import type { LoaderFunctionArgs, HeadersFunction, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'

import social from '@/assets/social.png?url'
import BlogCategoryLink from '@/components/BlogCategoryLink'
import BlogCategoryNav from '@/components/BlogCategoryNav'
import Paginator from '@/components/Paginator'
import SiteFooter from '@/components/SiteFooter'
import SiteHeader from '@/components/SiteHeader'
import { cacheControl } from '@/http.server'
import { findPaginatedBlogs } from '@/models/blog.server'
import { pageHelper } from '@/request'
import { formatLong } from '@/utils/date'

type LoaderData = typeof loader

export async function loader({ request }: LoaderFunctionArgs) {
  const page = pageHelper(request)

  const { blogs, pagination } = findPaginatedBlogs({ page: page, perPage: 25 })
  const { root } = page

  return json(
    {
      blogs: blogs,
      pagination: pagination,
      root: root
    },
    {
      headers: {
        'Cache-Control': cacheControl
      }
    }
  )
}

export const headers: HeadersFunction = ({ parentHeaders }) => ({
  'Cache-Control': parentHeaders.get('Cache-Control') ?? ''
})

export const meta: MetaFunction<LoaderData> = ({ data }) => {
  if (!data) return []

  const { root } = data

  const title = 'Blog — PlanetScale'
  const description = 'Posts about the PlanetScale platform, MySQL, databases, and more.'
  const image = `${root}${social}`

  return [
    { title },
    { name: 'description', content: description },
    { property: 'og:url', content: 'https://planetscale.com/blog' },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    { property: 'og:image', content: image },
    { property: 'og:description', content: description },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:site', content: '@PlanetScale' },
    { property: 'twitter:creator', content: '@PlanetScale' },
    { property: 'twitter:url', content: 'https://planetscale.com/blog' },
    { property: 'twitter:title', content: title },
    { property: 'twitter:description', content: description },
    { property: 'twitter:image', content: image },
    { tagName: 'link', rel: 'canonical', href: 'https://planetscale.com/blog' }
  ]
}

export default function BlogIndex() {
  const { blogs, pagination } = useLoaderData<LoaderData>()

  return (
    <>
      <SiteHeader />

      <main className='container my-6 max-w-7xl px-3 sm:px-5 lg:px-12'>
        <section>
          <div className='flex justify-between'>
            <h1>Blog</h1>
            <span>
              Get the <a href='/blog/feed.atom'>RSS feed</a>
            </span>
          </div>

          <BlogCategoryNav />

          {blogs.map((blog) => (
            <div key={blog.slug}>
              <hr className='dot-fill' />

              <div className='flex space-x-1'>
                <h2>
                  {blog.prefetch || blog.prefetch == null ? (
                    <Link
                      className='hover:bg-gray-900 hover:text-white hover:no-underline dark:hover:bg-gray-50 dark:hover:text-black'
                      prefetch='intent'
                      to={`/blog/${blog.slug}`}
                    >
                      {blog.title}
                    </Link>
                  ) : (
                    <a
                      className='hover:bg-gray-900 hover:text-white hover:no-underline dark:hover:bg-gray-50 dark:hover:text-black'
                      href={`/blog/${blog.slug}`}
                    >
                      {blog.title}
                    </a>
                  )}
                </h2>

                <span className='text-secondary'>
                  [
                  {blog.categories.map((category) => (
                    <BlogCategoryLink category={category} key={category} />
                  ))}
                  ]
                </span>
              </div>

              <p className='text-secondary'>
                By {blog.authors.join(', ')} | <time dateTime={blog.createdAt}>{formatLong(blog.createdAt)}</time>
              </p>

              <p>
                {blog.excerpt}{' '}
                <Link
                  className='text-default bg-blue-100 px-sm hover:bg-blue-600 hover:text-white dark:bg-blue-900 dark:text-blue dark:hover:bg-blue-500 dark:hover:text-black'
                  prefetch='intent'
                  to={`/blog/${blog.slug}`}
                >
                  →
                </Link>
              </p>
            </div>
          ))}

          <Paginator pagination={pagination} />
        </section>
      </main>

      <SiteFooter />
    </>
  )
}
